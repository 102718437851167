import { render, staticRenderFns } from "./quillEditor.vue?vue&type=template&id=31310c51&scoped=true&"
import script from "./quillEditor.vue?vue&type=script&lang=js&"
export * from "./quillEditor.vue?vue&type=script&lang=js&"
import style0 from "./quillEditor.vue?vue&type=style&index=0&lang=css&"
import style1 from "./quillEditor.vue?vue&type=style&index=1&id=31310c51&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31310c51",
  null
  
)

export default component.exports